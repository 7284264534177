import React from "react";
import './index.scss'
import moment from "moment";

const Obrada = (props) => {
    const [obrada, setObrada] = React.useState(0)
    React.useEffect(() => {
        let novo = 0
        for (let i = 0; i < props.leads?.length; i++) {
            for (let j = 1; j < props.leads[i]?.length; j++) {
                if (props.leads[i][j].status === 'IN_PROCESS'|| props.leads[i][j].status === 'PROCESSED') {
                    novo++
                }
            }
        }
        setObrada(novo)
    }, [props.leads])


    return < >
        {obrada}
    </>
}
export default Obrada