import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Radnik from "../../Components/Radnik";
import KPI from "../../Components/KPI";
import moment from "moment";
import Bonusi from "../../Components/Bonusi";
import BestCC from "../../Components/NajboljiCC";
import Obrada from "../../Components/Obrada";
import Promet from "../../Components/Promet";
import Popup from "../../Components/Popup";
import Minimum from "../../Components/Minimum";


const Tabela = () => {
    const search=useLocation().search
    const [cc1d, setcc1d] = React.useState([]);
    const [cc2d, setcc2d] = React.useState([]);
    const [cc3d, setcc3d] = React.useState([]);
    const [cc1l, setcc1l] = React.useState([]);
    const [cc2l, setcc2l] = React.useState([]);
    const [cc3l, setcc3l] = React.useState([]);
    const [newLeads, setNewLeads] = React.useState(0);
    const [activeTable, setActiveTable] = React.useState(1)
    const [date, setDate] = React.useState()
    const [presjek, setPresjek] = React.useState(false);
    const name = new URLSearchParams(search).get('cc');
    
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };


    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/checkLogin`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'error') {
                animateNavigate('/')
            }
        })
    }, [])
    let bubbleSort = (deals, leads) => {
        let len = deals?.length;
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < len; j++) {
                if (deals[j]?.length < deals[j + 1]?.length) {
                    let tmp = deals[j];
                    deals[j] = deals[j + 1];
                    deals[j + 1] = tmp;
                    let tmp1 = leads[j];
                    leads[j] = leads[j + 1];
                    leads[j + 1] = tmp1;
                }
            }
        }
        return [deals, leads];
    };
    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/crmData/getData`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            console.log(res.data.data)
            let cc1 = bubbleSort(res.data.data.cc1d, res.data.data.cc1l);
            let cc2 = bubbleSort(res.data.data.cc2d, res.data.data.cc2l);
            let cc3 = bubbleSort(res.data.data.cc3d, res.data.data.cc3l)
            setcc1d(cc1[0])
            setcc2d(cc2[0])
            setcc3d(cc3[0])
            setcc1l(cc1[1])
            setcc2l(cc2[1])
            setcc3l(cc3[1])
            setNewLeads(res.data.data.allLeads)
            setDate(moment(new Date()).format("hh:mm:ss"))



        })
        setTimeout(() => {

            getData()
        }, 600000)

    }
    const updateDeals = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/crmData/updateDeal`,
            data: {

            },
            ...backendModule.axiosConfig
        })
    }
    React.useEffect(() => {
        getData()
        
      
    }, [])
    React.useEffect(()=>{
        setActiveTable(parseInt(name))
    }, [new URLSearchParams(search).get('cc')])

    return <div className="component__table">

        <div className="component__table__header">


            <img style={{ 'width': '100px', 'borderRadius': '50%', 'marginRight': '50px' }} src='/images/bioplar.png' />
            <h1 style={{ 'width': 'calc(100% - 300px)' }}>Call center {activeTable}</h1>
            <div className="component__table__time">
                <p>Ažurirano u: {date}</p>
            </div>


        </div>
        <div className="tabelaContent">
            <div className="tabelaContent__left">
                {activeTable === 1 ? <BestCC data={cc1d} /> : null}
                {activeTable === 2 ? <BestCC data={cc2d} /> : null}
                {activeTable === 3 ? <BestCC data={cc3d} /> : null}
                <img style={{}} src='/images/obscalelogo.svg' />
            </div>
            <div className="tabelaContent__center">
                <div className="component__table__kpiContainer" >
                    <div className="component__table__kpiContainer__leads">
                        <img src="/images/new.png" />
                        <div>
                            <p>Novi leadovi</p>
                            <h3>{newLeads}</h3>
                        </div>
                    </div>
                    <div className="component__table__kpiContainer__leads">
                        <img className="phoneAnim" src="/images/phone.png" />
                        <div>
                            <p>Leadovi u obradi</p>
                            <h3><Obrada leads={activeTable === 1 ? cc1l : activeTable === 2 ? cc2l : cc3l} /></h3>
                        </div>
                    </div>
                    <div className="component__table__kpiContainer__leads">
                        <img src="/images/new.png" />
                        <div>
                            <p>Ukupan promet </p>
                            <Promet deals={activeTable === 1 ? cc1d : activeTable === 2 ? cc2d : cc3d} />
                        </div>
                    </div>

                </div>
                {activeTable === 1 ?
                    <div className="component__table__kpiContainer" >
                        <KPI cc='Deals' data={cc1d} />
                        <KPI cc='Leads' data={cc1l} />
                        <KPI cc='Prosjek' data={cc1d} />

                    </div> : null}
                {activeTable === 2 ?
                    <div className="component__table__kpiContainer" >
                        <KPI cc='Deals' data={cc2d} />
                        <KPI cc='Leads' data={cc2l} />
                        <KPI cc='Prosjek' data={cc2d} />


                    </div> : null}
                {activeTable === 3 ?
                    <div className="component__table__kpiContainer" >
                        <KPI cc='Deals' data={cc3d} />
                        <KPI cc='Leads' data={cc3l} />
                        <KPI cc='Prosjek' data={cc3d} />


                    </div> : null}
                <div className="component__table__tableData">
                    <div className="component__table__tableData__header">
                        <p style={{ 'width': '10%' }} >Ime  </p>
                        <div className="border"></div>
                        <p>Deals  <br />
                            <p className="tableRow">
                                <p>Danas</p><p>Jučer</p><p>Mjesec</p>
                            </p>
                        </p>
                        <div className="border"></div>
                        <p>Leads <br />
                            <p className="tableRow">
                                <p>Danas</p><p>Jučer</p><p>Mjesec</p>
                            </p>
                        </p>
                        <div className="border"></div>
                        <p>Prosječna vrijednost deala (€) <br />
                            <p className="tableRow">
                                <p>Danas</p><p>Jučer</p><p>Mjesec</p>
                            </p>
                        </p>
                        <div className="border"></div>
                        <p>Ostvareni promet<br />
                            <p className="tableRow">
                                <p>Danas</p><p>Jučer</p><p>Mjesec</p>
                            </p>

                        </p>
                    </div>
                    {activeTable === 1 && cc1l?.length ? cc1d.map((item, index) => {
                        return <Radnik class={index % 2 === 0 ? true : false} key={index} deals={item} leads={cc1l[index]} />
                    }) : null}
                    {activeTable === 2 && cc2l?.length ? cc2d.map((item, index) => {
                        return <Radnik class={index % 2 === 0 ? true : false} key={index} deals={item} leads={cc2l[index]} />
                    }) : null}
                    {activeTable === 3 && cc3l?.length ? cc3d.map((item, index) => {
                        return <Radnik class={index % 2 === 0 ? true : false} key={index} deals={item} leads={cc3l[index]} />
                    }) : null}
                </div>
            </div>
            <div className="tabelaContent__right">
                <Bonusi />
                <div className="component__table__obi">

                    <Minimum deals={activeTable === 1 ? cc1d : activeTable === 2 ? cc2d : cc3d} />
                </div>
            </div>
        </div>
        <Popup data={[[cc1d, cc1l], [cc2d, cc2l], [cc3d, cc3d]]} />







    </div>
};

export default Tabela;