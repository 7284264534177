import React from "react";
import moment from "moment";
import './index.scss'

const Promet = (props) => {
    const [obrada, setObrada] = React.useState(0);
    const [tod, setTod] = React.useState(0);
    const [yes, setYes] = React.useState(0)
    let week = new Date() - 24 * 60 * 60 * 1000;
    let danasnjiDatum = new Date();
    React.useEffect(() => {
        let novo = 0
        let prometD = 0;
        let prometJ = 0;
        for (let i = 0; i < props.deals?.length; i++) {
            for (let j = 1; j < props.deals[i]?.length; j++) {
                if (moment(props.deals[i][j]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                    prometD = prometD + props.deals[i][j].cijena;
                }
                if (moment(props.deals[i][j]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {
                    prometJ = prometJ + props.deals[i][j].cijena;

                }
                if (props.deals[i][j].status !== 'LOSE' &&props.deals[i][j].status !== 'C1:LOSE'  && props.deals[i][j].cijena !== 0) {
                    novo += props.deals[i][j].cijena
                }
            }
        }
        setObrada(novo);
        setTod(prometD)
        setYes(prometJ)
    }, [props.deals])


    return < div className="component__ccpromet">
        <p className="component__ccpromet__head"><p>Danas</p><p>Jucer</p><p>Mjesec</p></p>
        <p className="component__ccpromet__content"><p>{String(tod?.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}€</p><p>{String(yes?.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}€</p><p>{String(obrada?.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g, '$1,')}€</p></p>
        
    </div>
}
export default Promet