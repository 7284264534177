import React from "react";
import './index.scss'
import moment from "moment";
import KPI from "../KPI";
import Promet from "../Promet";
import Best from "../Najbolji";
import Minimum from "../Minimum";

const Popup = (props) => {
    const [timer, setTimer] = React.useState(true)
    const [visible, setVisible]=React.useState(false)

    let week = new Date() - 24 * 60 * 60 * 1000;
    let danasnjiDatum = new Date();

    const show=()=>{
        setVisible(true)
        setTimeout(()=>{
            rew()
        }, 300000)
    }
    const rew=()=>{
        setTimer(false)
        setTimeout(()=>{
            hide()
        }, 150000)
    }
    const hide=()=>{
        setVisible(false)
        setTimer(true)
        setTimeout(()=>{
            show()
        }, 7200000)
    }
    React.useEffect(()=>{
        setTimeout(()=>{
            show()
        }, 3200000)
    }, [])



    return <div className={visible?"components__popup components__popupV":"components__popup"}>
        <h1 className="components__popup__head">Presjek stanja {moment(new Date()).format('DD-MM-YYYY  hh:mm')}</h1>
        {timer ? <div className="components__popup__container">

            <div className="components__popup__container__cc">
                <h3>Call centar 1</h3>
                <KPI cc='Deals' data={props.data[0][0]} />
                <div className="component__table__kpiContainer__leads">
                    <img src="/images/new.png" />
                    <div>
                        <p>Ukupan promet </p>
                        <Promet deals={props.data[0][0]} />
                    </div>
                </div>
                <Minimum deals={props.data[0][0]} />
            </div>
            <div className="components__popup__container__cc">
                <h3>Call centar 2</h3>
                <KPI cc='Deals' data={props.data[1][0]} />
                <div className="component__table__kpiContainer__leads">
                    <img src="/images/new.png" />
                    <div>
                        <p>Ukupan promet </p>
                        <Promet deals={props.data[1][0]} />
                    </div>
                </div>
                <Minimum deals={props.data[1][0]} />
            </div>
            <div className="components__popup__container__cc">
                <h3>Call centar 3</h3>
                <KPI cc='Deals' data={props.data[2][0]} />
                <div className="component__table__kpiContainer__leads">
                    <img src="/images/new.png" />
                    <div>
                        <p>Ukupan promet </p>
                        <Promet deals={props.data[2][0]} />
                    </div>
                </div>
                <Minimum deals={props.data[2][0]} />
            </div>
            <Best data={[props.data[0][0], props.data[1][0], props.data[2][0]]} />
        </div> :
            <div className="rewardContainer">
                <div style={{ 'display': 'flex', 'marginLeft': '20px' }}>
                    <p style={{ 'width': '300px', 'textAlign': "center", 'marginRight': '20px', 'fontWeight': '900' }} >Dosegni level 4 sa prosjekom iznad 95€ i osvoji noćenje za 2 osobe Hotel Pino Nature Sarajevo + putni troškovi <br /><small style={{ 'fontWeight': '200' }}>Važi za najboljeg agenta</small></p>
                    <img  src="/images/pino.jpg" />
                </div>
                <div style={{ 'display': 'flex', 'marginLeft': '20px', }}>
                    <p style={{ 'width': '200px', 'textAlign': "center", 'marginRight': '20px', 'fontWeight': '900' }} >Dosegni level 5 sa prosjekom iznad 86€ i osvoji Samsung Galaxy Watch Active<br /> <small style={{ 'fontWeight': '200' }}>Važi za najboljeg agenta</small></p>
                    <img src="/images/watch.jpg" />
                </div>
                <div style={{ 'display': 'flex' }}>
                    <p style={{ 'width': '200px', 'textAlign': "center", 'marginRight': '20px', 'fontWeight': '900' }} >Dostigni level 6 u prodaji sa prosjekom iznad 86€ i osvoji novi Samsung Galaxy S21 <br /> <small style={{ 'fontWeight': '200' }}>Važi za najboljeg agenta</small></p>
                    <img  src="/images/samsung.jpg" />
                </div>
            </div>
        }

    </div>
}
export default Popup