import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Radnik from "../../Components/Radnik";
import KPI from "../../Components/KPI";
import moment from "moment";
import Bonusi from "../../Components/Bonusi";
import BestCC from "../../Components/NajboljiCC";
import Obrada from "../../Components/Obrada";
import Promet from "../../Components/Promet";
import Popup from "../../Components/Popup";
import Minimum from "../../Components/Minimum";


const Header = () => {
    const [ccmenu, setCcMenu] = React.useState(false)

    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };


    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/checkLogin`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'error') {
                animateNavigate('/')
            }
        })
    }, [])




    return <div className="component__header">

        <div className={ccmenu ? "component__table__navigation" : "component__table__navigation component__table__navigationHidden"}>
            <div onClick={() => { animateNavigate('/table?cc=1');setCcMenu(!ccmenu)}}> call centar 1 </div>
            <div onClick={() => { animateNavigate('/table?cc=2');setCcMenu(!ccmenu) }} > call centar 2 </div>
            <div onClick={() => { animateNavigate('/table?cc=3');setCcMenu(!ccmenu) }} > call centar 3 </div>
            <div onClick={() => { animateNavigate('/bonusi');setCcMenu(!ccmenu) }} > Bonusi</div>
        </div>





        <div onClick={() => { setCcMenu(!ccmenu) }} className="component__table__navigationButton">
            <div></div>
        </div>


    </div>
};

export default Header;