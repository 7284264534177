import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import leadovi from './leads.json'
const Login = () => {
    const username = React.useRef();
    const password = React.useRef();
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/checkLogin`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                animateNavigate('/table?cc=1')
            }
        })
    }, [])
    const userLogin = (e) => {
        e.preventDefault()
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data: {
                username: username.current.value,
                password: password.current.value,
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                animateNavigate('/table?cc=1')
            }
        })
    }
    
    return <div className="component__login">
        <div className="component__login__left">
            <img src="/images/logoblack.svg" />
            <h3>We are proud of our team </h3>
            <h1>Call center dashboard</h1>
        </div>
        <form className="component__login__right" onSubmit={(e) => { userLogin(e) }}>
            <p>Welcome back</p>
            <h1>Login to your account</h1>
            <label>Username</label>
            <input placeholder="Enter your username" type='text' required ref={username} />
            <label>Password</label>
            <input placeholder="Enter your password" type='password' required ref={password} />
            <button type="submit" >Login now</button>

        </form>
    </div>
};

export default Login;