import React from "react";
import './index.scss'
import moment from "moment";

const Best = (props) => {
    const [today, setToday] = React.useState();
    const [yes, setYes] = React.useState();
    const [month, setMonth] = React.useState()

    let week = new Date() - 24 * 60 * 60 * 1000;
    let danasnjiDatum = new Date();
    React.useEffect(() => {
        let najboljiDanasCC1 = { broj: 0 };
        let najboljiDanasCC2 = { broj: 0 };
        let najboljiDanasCC3 = { broj: 0 };
        let najboljiJuceCC1 = { broj: 0 };
        let najboljiJuceCC2 = { broj: 0 };
        let najboljiJuceCC3 = { broj: 0 };
        let najboljiMjesecCC1 = { broj: 0 };
        let najboljiMjesecCC2 = { broj: 0 };
        let najboljiMjesecCC3 = { broj: 0 };

        for (let i = 0; i < props.data[0]?.length; i++) {
            let temp0 = 0
            let temp00 = 0
            let cijena1 = 0
            let cijena11 = 0
            let cijena = 0;
            let temp = 0;
            for (let j = 1; j < props.data[0][i]?.length; j++) {
                if (moment(props.data[0][i][j]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY') && props.data[0][i][j]?.status !== 'LOSE' && props.data[0][i][j]?.status !== 'C1:LOSE' && props.data[0][i][j]?.cijena !== 0) {
                    temp0++
                    cijena1 = cijena1 + props.data[0][i][j].cijena

                }
                if (moment(props.data[0][i][j]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY') && props.data[0][i][j]?.status !== 'LOSE' && props.data[0][i][j]?.status !== 'C1:LOSE' && props.data[0][i][j]?.cijena !== 0) {
                    cijena11 = cijena11 + props.data[0][i][j].cijena
                    temp00++
                }
                if (moment(props.data[0][i][j]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM') && props.data[0][i][j]?.status !== 'LOSE' && props.data[0][i][j]?.status !== 'C1:LOSE' && props.data[0][i][j]?.cijena !== 0) {
                    cijena = cijena + props.data[0][i][j].cijena
                    temp++
                }
            }

            if (temp0 > najboljiDanasCC1.broj && Number(cijena1 / temp0) > 75) {
                najboljiDanasCC1 = { ID: props.data[0][i][0].ID, ime: props.data[0][i][0].ime, broj: temp0, prosjek: Number(cijena1 / temp0) }
            }
            if (temp00 > najboljiJuceCC1.broj && Number(cijena1 / temp0) > 75) {
                najboljiJuceCC1 = { ID: props.data[0][i][0].ID, ime: props.data[0][i][0].ime, broj: temp00, prosjek: Number(cijena11 / temp00) }
            }
            if (temp > najboljiMjesecCC1.broj && Number(cijena / temp) > 75) {
                najboljiMjesecCC1 = { ID: props.data[0][i][0].ID, ime: props.data[0][i][0].ime, broj: temp, prosjek: Number(cijena / temp) }
            }
        }
        for (let i = 0; i < props.data[1]?.length; i++) {
            let temp0 = 0
            let temp00 = 0
            let cijena1 = 0
            let cijena11 = 0
            let cijena = 0;
            let temp = 0;
            for (let j = 1; j < props.data[1][i]?.length; j++) {
                if (moment(props.data[1][i][j]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY') && props.data[1][i][j]?.status !== 'LOSE' && props.data[1][i][j]?.status !== 'C1:LOSE' && props.data[1][i][j]?.cijena !== 0) {
                    temp0++
                    cijena1 = cijena1 + props.data[1][i][j].cijena

                }
                if (moment(props.data[1][i][j]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY') && props.data[1][i][j]?.status !== 'LOSE' && props.data[1][i][j]?.status !== 'C1:LOSE' && props.data[1][i][j]?.cijena !== 0) {
                    cijena11 = cijena11 + props.data[1][i][j].cijena
                    temp00++
                }
                if (moment(props.data[1][i][j]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM') && props.data[1][i][j]?.status !== 'LOSE' && props.data[1][i][j]?.cijena !== 0) {
                    cijena = cijena + props.data[1][i][j].cijena
                    temp++
                }
            }

            if (temp0 > najboljiDanasCC2.broj && Number(cijena1 / temp0) > 65) {
                najboljiDanasCC2 = { ID: props.data[1][i][0].ID, ime: props.data[1][i][0].ime, broj: temp0, prosjek: Number(cijena1 / temp0) }
            }
            if (temp00 > najboljiJuceCC2.broj && Number(cijena1 / temp0) > 65) {
                najboljiJuceCC2 = { ID: props.data[1][i][0].ID, ime: props.data[1][i][0].ime, broj: temp00, prosjek: Number(cijena11 / temp00) }
            }
            if (temp > najboljiMjesecCC2.broj && Number(cijena / temp) > 75) {
                najboljiMjesecCC2 = { ID: props.data[1][i][0].ID, ime: props.data[1][i][0].ime, broj: temp, prosjek: Number(cijena / temp) }
            }
        }
        for (let i = 0; i < props.data[2]?.length; i++) {
            let temp0 = 0
            let temp00 = 0
            let cijena1 = 0
            let cijena11 = 0
            let cijena = 0;
            let temp = 0;
            for (let j = 1; j < props.data[2][i]?.length; j++) {
                if (moment(props.data[2][i][j]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY') && props.data[2][i][j]?.status !== 'LOSE' && props.data[2][i][j]?.status !== 'C1:LOSE' && props.data[2][i][j]?.cijena !== 0) {
                    temp0++
                    cijena1 = cijena1 + props.data[2][i][j].cijena

                }
                if (moment(props.data[2][i][j]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY') && props.data[2][i][j]?.status !== 'LOSE' && props.data[2][i][j]?.status !== 'C1:LOSE' && props.data[2][i][j]?.cijena !== 0) {
                    cijena11 = cijena11 + props.data[2][i][j].cijena
                    temp00++
                }
                if (moment(props.data[2][i][j]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM') && props.data[2][i][j]?.status !== 'LOSE' && props.data[2][i][j]?.status !== 'C1:LOSE' && props.data[2][i][j]?.cijena !== 0) {
                    cijena = cijena + props.data[2][i][j].cijena
                    temp++
                }
            }

            if (temp0 > najboljiDanasCC3.broj && Number(cijena1 / temp0) > 65) {
                najboljiDanasCC3 = { ID: props.data[2][i][0].ID, ime: props.data[2][i][0].ime, broj: temp0, prosjek: Number(cijena1 / temp0) }
            }
            if (temp00 > najboljiJuceCC3.broj && Number(cijena1 / temp0) > 65) {
                najboljiJuceCC3 = { ID: props.data[2][i][0].ID, ime: props.data[2][i][0].ime, broj: temp00, prosjek: Number(cijena11 / temp00) }
            }
            if (temp > najboljiMjesecCC3.broj && Number(cijena / temp) > 75) {
                najboljiMjesecCC3 = { ID: props.data[2][i][0].ID, ime: props.data[2][i][0].ime, broj: temp, prosjek: Number(cijena / temp) }
            }
        }

        if (najboljiDanasCC1.broj > najboljiDanasCC2.broj && najboljiDanasCC1.broj > najboljiDanasCC3.broj) {
            setToday(najboljiDanasCC1)
        } else if (najboljiDanasCC2.broj > najboljiDanasCC3.broj) {
            setToday(najboljiDanasCC2)
        } else {
            setToday(najboljiDanasCC3)
        }
        if (najboljiJuceCC1.broj > najboljiJuceCC2.broj && najboljiJuceCC1.broj > najboljiJuceCC3.broj) {
            setYes(najboljiJuceCC1)
        } else if (najboljiJuceCC2.broj > najboljiJuceCC3.broj) {
            setYes(najboljiJuceCC2)
        } else {
            setYes(najboljiJuceCC3)
        }
        if (najboljiMjesecCC1.broj > najboljiMjesecCC2.broj && najboljiMjesecCC1.broj > najboljiMjesecCC3.broj) {
            setMonth(najboljiMjesecCC1)
        } else if (najboljiMjesecCC2.broj > najboljiMjesecCC3.broj) {
            setMonth(najboljiMjesecCC2)
        } else {
            setMonth(najboljiMjesecCC3)
        }
    }, [props.data])



    return <div className="components__best">
        <div>
            <h3>Najbolji radnik danas </h3>
            <img src={`/images/radnici/${today?.ID}.PNG`}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/radnici/undefined.png";
                }}
            />
            <p>{today?.ime}</p>
            <p style={{ 'marginBottom': 0, 'fontWeight': 900, 'fontSize': '20px' }}> {today?.broj}</p>
            <p style={{ 'color': '#707A89', 'fontSize': '13px', 'margin': 0 }}>Broj dobrih dealova</p>
            <p style={{ 'marginBottom': 0, 'fontWeight': 900, 'fontSize': '20px' }}> {(today?.prosjek)?.toFixed(2)}€</p>
            <p style={{ 'color': '#707A89', 'fontSize': '13px', 'margin': 0 }}>Prosječna vrijednost prodaje</p>

        </div>
        <div >
            <h3>Najbolji radnik jučer</h3>
            <img src={`/images/radnici/${yes?.ID}.PNG`}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/radnici/undefined.png";
                }}
            />
            <p>{yes?.ime}</p>
            <p style={{ 'marginBottom': 0, 'fontWeight': 900, 'fontSize': '20px' }}> {yes?.broj}</p>
            <p style={{ 'color': '#707A89', 'fontSize': '13px', 'margin': 0 }}>Broj dobrih dealova</p>
            <p style={{ 'marginBottom': 0, 'fontWeight': 900, 'fontSize': '20px' }}> {(yes?.prosjek)?.toFixed(2)}€</p>
            <p style={{ 'color': '#707A89', 'fontSize': '13px', 'margin': 0 }}>Prosječna vrijednost prodaje</p>
        </div>
        <div style={{ 'borderBottom': 'none' }} >
            <h3 style={{ 'textAlign': 'center' }} >Najbolji radnik mjesec</h3>
            <img src={`/images/radnici/${month?.ID}.PNG`}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/radnici/undefined.png";
                }}
            />
            <p>{month?.ime}</p>
            <p style={{ 'marginBottom': 0, 'fontWeight': 900, 'fontSize': '20px' }}> {month?.broj}</p>
            <p style={{ 'color': '#707A89', 'fontSize': '13px', 'margin': 0 }}>Broj dobrih dealova</p>
            <p style={{ 'marginBottom': 0, 'fontWeight': 900, 'fontSize': '20px' }}> {(month?.prosjek)?.toFixed(2)}€</p>
            <p style={{ 'color': '#707A89', 'fontSize': '13px', 'margin': 0 }}>Prosječna vrijednost prodaje</p>
        </div>
    </div>
}
export default Best