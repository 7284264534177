import React from "react";
import './index.scss'
import moment from "moment";

const Bonusi = (props) => {



    return <div className="components__bonus">
        <div className="components__bonus__header">
            <p>Promet</p>
            <p>Bonus</p>
        </div>
        <div className="components__bonus__content">
            <p>1. 15,000 €</p>
            <p>50 €</p>
        </div>
        <div className="components__bonus__content">
            <p>2. 20,000 €</p>
            <p>75 €</p>
        </div>
        <div className="components__bonus__content">
            <p>3. 25,000 €</p>
            <p>150 €</p>
        </div>
        <div className="components__bonus__content">
            <p>4. 30,000 €</p>
            <p>300 €</p>
        </div>
        <div className="components__bonus__content">
            <p>5. 35,000 €</p>
            <p>400 €</p>
        </div>
        <div className="components__bonus__content">
            <p>6. 50,000 €</p>
            <p>600 €</p>
        </div>
        <div className="components__bonus__content">
            <p>7. 65,000 €</p>
            <p>1000 €</p>
        </div>
        {/* <div className="components__bonus__content">
            <p>100,000 €</p>
            <p>1000 €</p>
        </div> */}
    </div>
}
export default Bonusi