import React from "react";
import './index.scss'
import moment from "moment";

const KPI = (props) => {
    const [today, setToday] = React.useState(0);
    const [todayGood, setTodayGood] = React.useState(0);
    const [yes, setYes] = React.useState(0);
    const [yesGood, setYesGood] = React.useState(0);
    const [month, setMonth] = React.useState(0);
    const [monthGood, setMonthGood] = React.useState(0);

    let week = new Date() - 24 * 60 * 60 * 1000;
    let danasnjiDatum = new Date();



    React.useEffect(() => {
        let danas = 0;
        let danasDobri = 0;
        let juce = 0;
        let juceDobri = 0;
        let mjesec = 0;
        let mjesecDobri = 0;
        if (props.cc === 'Deals') {

            for (let j = 0; j < props.data?.length; j++) {

                for (let i = 1; i < props.data[j]?.length; i++) {
                    if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                        danas++

                    }
                    if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {

                        juce++
                    }
                    if (moment(props.data[j][i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                        mjesec++
                    }
                    if (props.data[j][i]?.status !== 'LOSE' &&props.data[j][i]?.status !== 'C1:LOSE'  && props.data[j][i]?.cijena !== 0) {
                        if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                            danasDobri++;
                        }
                        if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {
                            juceDobri++;

                        }
                        if (moment(props.data[j][i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                            mjesecDobri++;
                        }

                    }
                }
            }

        }
        if (props.cc === 'Leads') {

            for (let j = 0; j < props.data?.length; j++) {

                for (let i = 1; i < props.data[j]?.length; i++) {
                    if (props.data[j][i]?.status === 'CONVERTED') {
                        if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                            danasDobri++
                        }
                        if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {
                            juceDobri++
                        }
                        if (moment(props.data[j][i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                            mjesecDobri++
                        }

                    } else if (props.data[j][i]?.status === 'JUNK' || props.data[j][i]?.status === '1') {
                        if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                            danas++
                        }
                        if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {
                            juce++
                        }
                        if (moment(props.data[j][i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                            mjesec++
                        }

                    }
                }
            }
        }
        if (props.cc === 'Prosjek') {

            for (let j = 0; j < props.data?.length; j++) {

                for (let i = 1; i < props.data[j]?.length; i++) {

                    if (props.data[j][i]?.status !== 'LOSE' &&props.data[j][i]?.status !== 'C1:LOSE' && props.data[j][i]?.cijena !== 0) {
                        if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                            danas = danas + props.data[j][i]?.cijena
                            danasDobri++;
                        }
                        if (moment(props.data[j][i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {
                            juce = juce + props.data[j][i]?.cijena
                            juceDobri++;

                        }
                        if (moment(props.data[j][i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                            mjesec = mjesec + props.data[j][i]?.cijena
                            mjesecDobri++;
                        }

                    }
                }
            }

        }
        setToday(danas);
        setTodayGood(danasDobri);
        setYes(juce);
        setYesGood(juceDobri);
        setMonth(mjesec);
        setMonthGood(mjesecDobri)
    }, [props.data])


    return <div className="components__kpi">
        <h1>{props.cc}</h1>
        {props.cc === 'Deals' ?
            <div className="components__kpi__content">
                <div>
                    <p style={{'color':'#707A89', 'margin':0 }}><p>Vrijeme</p> <p>Ukupno</p><p>Dobri</p><p>Loši</p></p>
                </div>
                <div>
                    <p ><p>Danas</p>  <p style={{ 'color': 'blue' }}>{today}</p><p style={{ 'color': 'green' }}>{todayGood}</p><p style={{ 'color': 'red' }}>{today - todayGood}</p></p>
                </div>
                <div>

                    <p><p>Jucer</p><p style={{ 'color': 'blue' }}>{yes}</p><p style={{ 'color': 'green' }}>{yesGood}</p><p style={{ 'color': 'red' }}>{yes - yesGood}</p></p>
                </div>
                <div>
                    <p> <p>Mjesec</p>  <p style={{ 'color': 'blue' }}>{month}</p><p style={{ 'color': 'green' }}>{monthGood}</p><p style={{ 'color': 'red' }}>{month - monthGood}</p></p>
                </div>
            </div> : null
        }
        {props.cc === 'Leads' ?
            <div className="components__kpi__content">
                <div>
                    <p style={{'color':'#707A89', 'margin':0 }}><p>Vrijeme</p> <p>Ukupno</p><p>Dobri</p><p>Loši</p></p>
                </div>
                <div>

                    <p> <p>Danas</p><p style={{ 'color': 'blue' }}>{todayGood + today}</p><p style={{ 'color': 'green' }}>{todayGood}</p><p style={{ 'color': 'red' }}>{today}</p></p>
                </div>
                <div>

                    <p><p>Jucer</p><p style={{ 'color': 'blue' }}>{yes + yesGood}</p><p style={{ 'color': 'green' }}>{yesGood}</p><p style={{ 'color': 'red' }}>{yes}</p></p>
                </div>
                <div>

                    <p><p>Mjesec</p><p style={{ 'color': 'blue' }}>{month + monthGood}</p><p style={{ 'color': 'green' }}>{monthGood}</p><p style={{ 'color': 'red' }}>{month}</p></p>
                </div>
            </div> : null}
        {props.cc === 'Prosjek' ?
            <div className="components__kpi__content components__kpi__content2">
                <div>
                    <p style={{'color':'#707A89', 'margin':0 }}><p>Vrijeme</p> <p>Prosječna vrijednost</p></p>
                </div>
                <div>
                    <p>
                        <p>Danas</p>
                        <p>{isNaN(Number(today / todayGood)) ? 0 : (Number(today / todayGood))?.toFixed(2)} €</p>
                    </p>
                </div>
                <div>
                    <p>
                        <p>Jucer</p>
                        <p>{isNaN(Number(yes / yesGood)) ? 0 : (Number(yes / yesGood))?.toFixed(2)} €</p>
                    </p>
                </div>
                <div>
                    <p>
                        <p>Mjesec</p>
                        <p>{isNaN(Number(month / monthGood)) ? 0 : (Number(month / monthGood)).toFixed(2)} €</p>
                    </p>
                </div>
            </div> : null}
    </div>
}
export default KPI