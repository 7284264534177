import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./routes/login";
import * as backendModule from './modules/backendModule';
import Tabela from "./routes/tabela";
import Pregled from "./routes/pregled";
import Bonus from "./routes/Bonusi";
import Header from "./Components/Header";

const App = () => {

  return <Router>
    <div className="component__contentWrapper">
      <Header/>
      <Routes>

        <Route path="/" element={<Login />} />
        <Route path='/table' element={<Tabela />} />
        <Route path='/bonusi' element={<Bonus />} />

      </Routes>
    </div>
  </Router>
};

export default App;

