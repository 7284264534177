import React from "react";
import './index.scss'
import moment from "moment";

const Radnik = (props) => {
    const [dobri, setDobri] = React.useState(0)
    const [losi, setLosi] = React.useState(0)

    //novo sa vremenom
    const [goodLeadToday, setGoodLeadToday] = React.useState(0)
    const [badLeadToday, setBadLeadToday] = React.useState(0)
    const [goodLeadWeek, setGoodLeadWeek] = React.useState(0);
    const [badLeadWeek, setBadLeadWeek] = React.useState(0);
    const [goodLeadsMonth, setGoodLeadsMonth] = React.useState(0);
    const [badLeadsMonth, setBadLeadsMonth] = React.useState(0);
    const [prosjekDay, setProsjekDay] = React.useState(0);
    const [prosjekWeek, setProsjekWeek] = React.useState(0);
    const [prosjekMonth, setProsjekMonth] = React.useState(0);
    const [finishToday, setFinishToday] = React.useState(0);
    const [finishWeek, setFinishWeek] = React.useState(0);
    const [finishMonth, setFinishMonth] = React.useState(0);
    const [goodDealsDay, setGoodDealsDay] = React.useState(0);
    const [goodDealsYes, setGoodDealsYes] = React.useState(0)
    const [goodDealsM, setGoodDealsM] = React.useState(0)
    const [traffic, setTraffic] = React.useState(0)
    const [trafficD, setTrafficD] = React.useState(0)
    const [trafficY, setTrafficY] = React.useState(0)
    let week = new Date() - 24 * 60 * 60 * 1000;
    let danasnjiDatum = new Date();
    // const last7Days = () => {

    //     for (var i = 0; i < 7; i++) {
    //         week.push(moment(danasnjiDatum).subtract(i, 'days').format('DD-MM-YYYY'))
    //     }
    // }

    React.useEffect(() => {

        if (!props?.leads?.length) return
        if (!props?.deals?.length) return
        let prosjekDan = 0;
        let prosjekMjesec = 0;
        let prosjekSedmica = 0
        let obradjenoDanas = 0
        let obradjenoSedmica = 0;
        let obradjenoMjesec = 0;
        let dobarLeadDanas = 0
        let losLeadDanas = 0
        let dobarLeadSedmica = 0
        let losLeadSedmica = 0
        let dobarLeadMjesec = 0;
        let losLeadMjesec = 0
        let dealDanas = 0;
        let dealSedmica = 0;
        let dealMjesec = 0;
        let promet = 0
        let prometD = 0;
        let prometJ = 0
        for (let i = 1; i < props.leads?.length; i++) {
            if (props.leads[i].status === 'CONVERTED') {
                if (moment(props.leads[i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                    dobarLeadDanas++
                }
                if (moment(props.leads[i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {
                    dobarLeadSedmica++
                }
                if (moment(props.leads[i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                    dobarLeadMjesec++
                }

            } else if (props.leads[i].status === 'JUNK' || props.leads[i].status == '1') {
                if (moment(props.leads[i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                    losLeadDanas++
                }
                if (moment(props.leads[i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {
                    losLeadSedmica++
                }
                if (moment(props.leads[i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                    losLeadMjesec++
                }

            }
        }
        for (let i = 1; i < props.deals?.length; i++) {

            if (moment(props.deals[i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                obradjenoDanas++

            }
            if (moment(props.deals[i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {

                obradjenoSedmica++
            }
            if (moment(props.deals[i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                obradjenoMjesec++
            }

            if (props.deals[i].status !== 'LOSE' &&props.deals[i].status !== 'C1:LOSE' && props.deals[i].cijena !== 0) {
                if (moment(props.deals[i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                    prosjekDan = prosjekDan + props.deals[i].cijena;
                    dealDanas++;
                }
                if (moment(props.deals[i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {
                    prosjekSedmica = prosjekSedmica + props.deals[i].cijena;
                    dealSedmica++;

                }
                if (moment(props.deals[i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                    prosjekMjesec = prosjekMjesec + props.deals[i].cijena;
                    dealMjesec++;
                }

            }
            if (props.deals[i].status !== 'LOSE' &&props.deals[i].status !== 'C1:LOSE'  && props.deals[i].cijena !== 0) {
                if (moment(props.deals[i]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY')) {
                    prometD =prometD+ props.deals[i].cijena;
                }
                if (moment(props.deals[i]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY')) {
                    prometJ =  prometJ+props.deals[i].cijena;

                }
                if (moment(props.deals[i]?.kreirano).format('MM') === moment(danasnjiDatum).format('MM')) {
                    promet = promet + props.deals[i].cijena;
                }

            }
        }



        setGoodLeadToday(dobarLeadDanas)
        setBadLeadToday(losLeadDanas)
        setGoodLeadWeek(dobarLeadSedmica)
        setBadLeadWeek(losLeadSedmica)
        setGoodLeadsMonth(dobarLeadMjesec);
        setBadLeadsMonth(losLeadMjesec);
        setFinishToday(obradjenoDanas);
        setFinishWeek(obradjenoSedmica);
        setFinishMonth(obradjenoMjesec);
        setProsjekDay(prosjekDan / dealDanas)
        setProsjekWeek(prosjekSedmica / dealSedmica)
        setProsjekMonth(prosjekMjesec / dealMjesec)
        setGoodDealsDay(dealDanas);
        setGoodDealsYes(dealSedmica);
        setGoodDealsM(dealMjesec)
        setTraffic(promet)
        setTrafficD(prometD)
        setTrafficY(prometJ)
    }, [props.leads])

    return <div className={props.class ? `components__radnik radnik2` : 'components__radnik'}>
        <p className="imeradnik" >{props.deals[0].ime}</p>
        <div className="border"></div>
        <p >
            <h7 className="components__radnik__headMobile">Deals</h7>
            <p className="tableRow">
                <p>
                    <span style={{ 'color': 'blue' }}>{finishToday}</span>/
                    <span style={{ 'color': 'green' }}>{goodDealsDay}</span>/
                    <span style={{ 'color': 'red' }}>{finishToday - goodDealsDay}</span>
                </p>
                <div className="border2"></div>
                <p>
                    <span style={{ 'color': 'blue' }}>{finishWeek}</span>/
                    <span style={{ 'color': 'green' }}>{goodDealsYes}</span>/
                    <span style={{ 'color': 'red' }}>{finishWeek - goodDealsYes}</span>
                </p>
                <div className="border2"></div><p>
                    <span style={{ 'color': 'blue' }}>{finishMonth}</span>/
                    <span style={{ 'color': 'green' }}>{goodDealsM}</span>/
                    <span style={{ 'color': 'red' }}>{finishMonth - goodDealsM}</span>
                </p>
            </p>
        </p>
        <div className="border"></div>
        <p >
        <h7 className="components__radnik__headMobile">Leads</h7>
            <p className="tableRow">
                <p>
                    <span style={{ 'color': 'blue' }}>{goodLeadToday + badLeadToday}</span>/
                    <span style={{ 'color': 'green' }}>{goodLeadToday}</span>/
                    <span style={{ 'color': 'red' }}>{badLeadToday}</span>
                </p>
                <div className="border2"></div>
                <p>
                    <span style={{ 'color': 'blue' }}>{goodLeadWeek + badLeadWeek}</span>/
                    <span style={{ 'color': 'green' }}>{goodLeadWeek}</span>/
                    <span style={{ 'color': 'red' }}>{badLeadWeek}</span>
                </p>
                <div className="border2"></div>
                <p>
                    <span style={{ 'color': 'blue' }}>{goodLeadsMonth + badLeadsMonth}</span>/
                    <span style={{ 'color': 'green' }}>{goodLeadsMonth}</span>/
                    <span style={{ 'color': 'red' }}>{badLeadsMonth}</span>
                </p>
            </p>


        </p>
        <div className="border"></div>
        <p>
        <h7 className="components__radnik__headMobile">Prosjek</h7>
            <p className="tableRow">
                <p  >{isNaN(Number(prosjekDay)) ? 0 : Number(prosjekDay)?.toFixed(2)}</p>
                <div className="border2"></div>
                <p >{isNaN(Number(prosjekWeek)) ? 0 : Number(prosjekWeek)?.toFixed(2)} </p>

                <div className="border2"></div>
                <p >{isNaN(Number(prosjekMonth)) ? 0 : Number(prosjekMonth)?.toFixed(2)} </p>
            </p>
        </p>
        <div className="border"></div>
        <p>
        <h7 className="components__radnik__headMobile">Promet</h7>
            <p className="tableRow">
                <p>{isNaN(Number(trafficD)) ? 0 :(String(Number(trafficD)?.toFixed(2)).replace(/(.)(?=(\d{3})+$)/g,'$1,')) }€
               
                </p>
                <div className="border2"></div>
                <p>{isNaN(Number(trafficY)) ? 0 : String((Number(trafficY))?.toFixed(2)).replace(/(.)(?=(\d{3})+$)/g,'$1,')}€</p>
                <div className="border2"></div>
                <p>{isNaN(Number(traffic)) ? 0 : String(Number(traffic)?.toFixed(2)).replace(/(.)(?=(\d{3})+$)/g,'$1,')}€</p>
            </p>
        </p>
    </div>
}
export default Radnik