import "./index.scss";
import * as backendModule from '../../modules/backendModule';
import AnimateModule from '../../modules/animateModule'
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import RadnikBonus from "../../Components/RadnikBonus";



const Bonus = () => {
    const [mjesec, setMjesec] = React.useState()
    const [godina, setGodina] = React.useState()
    const [calculating, setCalc] = React.useState(false)
    const [izabrano, setIzabrano] = React.useState(false)
    const [data, setData] = React.useState()
    const [mjesecL, setMjesecL] = React.useState()

    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    let godineA = []
    const godine = () => {
        let temp = parseInt(moment(new Date()).format("YYYY"))
        for (let i = 0; i < 13; i++) {
            godineA.push(temp)
            temp--
        }
    }
    godine()
    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/checkLogin`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'error') {
                animateNavigate('/')
            }
        })
    }, [])
    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/checkLogin`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'error') {
                animateNavigate('/')
            }
        })
    }, [])

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/crmData/getBonus`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data.data)
            console.log(res.data.data)



        })
        setTimeout(() => {

            getData()
        }, 600000)

    }

    React.useEffect(() => {
        getData()
    }, [])
    const calculate = () => {
        if (!mjesec || !godina) {
            setIzabrano(true)
        } else {
            setCalc(true)
        }
    }

    return <div className="component__bonus">
        {!calculating ? <>
            <h1>Izaberite period za racunanje bonusa:</h1>
            <div className="component__bonus__month">
                <div>
                    <h4>Mjesec</h4>
                    <p className={mjesec === 1 && 'mjesecActive'} onClick={() => { setMjesec(1); setMjesecL('Januar') }}>Januar</p>
                    <p className={mjesec === 2 && 'mjesecActive'} onClick={() => { setMjesec(2); setMjesecL('Februar') }}>Februar</p>
                    <p className={mjesec === 3 && 'mjesecActive'} onClick={() => { setMjesec(3); setMjesecL('Mart') }}>Mart</p>
                    <p className={mjesec === 4 && 'mjesecActive'} onClick={() => { setMjesec(4); setMjesecL('April') }}>April</p>
                    <p className={mjesec === 5 && 'mjesecActive'} onClick={() => { setMjesec(5); setMjesecL('Maj') }}>Maj</p>
                    <p className={mjesec === 6 && 'mjesecActive'} onClick={() => { setMjesec(6); setMjesecL('Jun') }}>Jun</p>
                    <p className={mjesec === 7 && 'mjesecActive'} onClick={() => { setMjesec(7); setMjesecL('Jul') }}>Jul</p>
                    <p className={mjesec === 8 && 'mjesecActive'} onClick={() => { setMjesec(8); setMjesecL('August') }}>August</p>
                    <p className={mjesec === 9 && 'mjesecActive'} onClick={() => { setMjesec(9); setMjesecL('Septembar') }}>Septembar</p>
                    <p className={mjesec === 10 && 'mjesecActive'} onClick={() => { setMjesec(10); setMjesecL('Oktobar') }}>Oktobar</p>
                    <p className={mjesec === 11 && 'mjesecActive'} onClick={() => { setMjesec(11); setMjesecL('Novembar') }}>Novembar</p>
                    <p className={mjesec === 12 && 'mjesecActive'} onClick={() => { setMjesec(12); setMjesecL('Decembar') }}>Decembar</p>
                </div>
                <div>
                    <h4>Godina</h4>
                    {godineA?.map(item => {
                        return <p className={godina === item && 'godinaActive'} onClick={() => { setGodina(item) }}>{item}</p>
                    })}
                </div>
                <div className="component__bonus__month__transparent">
                    {izabrano && <p>Molimo vas izaberite prvo mjesec i godinu</p>}
                    <button onClick={() => { calculate() }}>Izracunaj bonus</button></div>

            </div>


        </> : <>
            <h1>Bonusi za {mjesecL} {godina} godine</h1>
            <p>tabela azurirana: {data.date}</p>
            <div className="component__bonus__tabela">
                <div className="component__bonus__tabela__header">
                    <p>Ime i prezime</p>
                    <p>Broj dobrih dealova</p>
                    <p>Ostvareni bonus na promet (mjesecni promet)</p>
                    <p>Ostvareni bonus na prosjek (Ostvareni prosjek)</p>
                    <p>Ostvareni bonus na osnovu procenta dobrih leadova (procenat potvrdjenih leadova)</p>
                    <p>Ukupan bonus</p>
                </div>
                {data?.dealsBonus?.map((item, index) => {
                    return <RadnikBonus color={index % 2 === 0 ? { 'backgroundColor': '#eaf6ff' } : { 'backgroundColor': '#white' }} deals={data.dealsBonus[index]} leads={data.leadsBonus[index]} month={mjesec} year={godina} />
                })}
            </div>
        </>}
    </div>
};

export default Bonus;