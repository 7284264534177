import React from "react";
import './index.scss'
import moment from "moment";

const Minimum = (props) => {
    const [min, setMin] = React.useState(0)
    const [ostvareno, setOstvareno] = React.useState(0)
    const [min2, setMin2] = React.useState(0)
    const [ostvareno2, setOstvareno2] = React.useState(0)
    const [minj, setMinj] = React.useState(0)
    const [ostvarenoj, setOstvarenoj] = React.useState(0)
    const [minj2, setMinj2] = React.useState(0)
    const [ostvarenoj2, setOstvarenoj2] = React.useState(0)
    let danasnjiDatum = new Date();
    let week = new Date() - 24 * 60 * 60 * 1000;
    React.useEffect(() => {
        let smjena = [];
        let ostv = 0;
        let ostv2 = 0

        let smjenaj = [];
        let ostvj = 0;
        let ostvj2 = 0

        for (let i = 0; i < props.deals?.length; i++) {
            let temp = 0
            let temp2 = 0
            let tempj = 0
            let tempj2 = 0
            for (let j = 1; j < props.deals[i]?.length; j++) {
                if (moment(props.deals[i][j]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY') && props.deals[i][j]?.status !== 'LOSE' &&props.deals[i][j]?.status !== 'C1:LOSE' && props.deals[i][j]?.cijena !== 0) {
                    temp = temp + Number(moment(props.deals[i][j]?.kreirano).format('HH'))
                    temp2++


                }
                if (j === props.deals[i]?.length - 1) {
                    if (Number(temp / temp2) <= 13) {
                        smjena.push(1)
                    } else if (Number(temp / temp2) > 13) {
                        smjena.push(2)
                    } else (
                        smjena.push(0)
                    )
                }
                if (moment(props.deals[i][j]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY') && props.deals[i][j]?.status !== 'LOSE' &&props.deals[i][j]?.status !== 'C1:LOSE' &&  props.deals[i][j]?.cijena !== 0) {
                    tempj = tempj + Number(moment(props.deals[i][j]?.kreirano).format('HH'))
                    tempj2++


                }
                if (j === props.deals[i]?.length - 1) {
                    if (Number(tempj / tempj2) < 14) {
                        smjenaj.push(1)
                    } else if (Number(tempj / tempj2) >= 14) {
                        smjenaj.push(2)
                    } else (
                        smjenaj.push(0)
                    )
                }
            }

        }
        for (let i = 0; i < props.deals?.length; i++) {
            for (let j = 1; j < props.deals[i]?.length; j++) {
                if (moment(props.deals[i][j]?.kreirano).format('DD-MM-YYYY') === moment(danasnjiDatum).format('DD-MM-YYYY') && props.deals[i][j]?.status !== 'LOSE' &&props.deals[i][j]?.status !== 'C1:LOSE' && props.deals[i][j]?.cijena !== 0) {
                    if (smjena[i] === 1) {
                        ostv = ostv + props.deals[i][j]?.cijena
                    } else if (smjena[i] === 2) {
                        ostv2 = ostv2 + props.deals[i][j]?.cijena
                    }
                }
                if (moment(props.deals[i][j]?.kreirano).format('DD-MM-YYYY') === moment(week).format('DD-MM-YYYY') && props.deals[i][j]?.status !== 'LOSE'  && props.deals[i][j]?.cijena !== 0) {
                    if (smjenaj[i] === 1) {
                        ostvj = ostvj + props.deals[i][j]?.cijena
                    } else if (smjenaj[i] === 2) {
                        ostvj2 = ostvj2 + props.deals[i][j]?.cijena
                    }
                }
            }

        }
        let min = 0;
        let min1 = 0;
        let minjtemp = 0;
        let minj1 = 0;
        for (let i = 0; i < smjena.length; i++) {
            if (smjena[i] === 1) {
                min++
            } else if (smjena[i] === 2) {
                min1++
            }
        }
        for (let i = 0; i < smjenaj.length; i++) {
            if (smjenaj[i] === 1) {
                minjtemp++
            } else if (smjenaj[i] === 2) {
                minj1++
            }
        }
        setOstvareno(ostv);
        setOstvareno2(ostv2);
        setMin(min );
        setMin2(min1 )
        setOstvarenoj(ostvj);
        setOstvarenoj2(ostvj2);
        setMinj(minjtemp );
        setMinj2(minj1 )

    }, [props.deals])


    
    return <>
        <div className='components__minimum components__minimumGreen' >
            <h3>Danas 1. smjena:</h3>
            <h5> <normal>Predviđeno:</normal>  <strong>{min* 15 * 75}€</strong>

            </h5>
            <h5><normal>Ostvareno:</normal><strong>{ostvareno?.toFixed(2)}€</strong> </h5>
            <h5><normal>Broj radnika:</normal> <strong>{min}</strong></h5>
            <div className="components__minimum__progres">
                <div style={(100 / (min* 15 * 75)) * ostvareno < 50 ? { 'width': `${(100 / (min* 15 * 75)) * ostvareno}%`, 'backgroundColor': "red" } : (100 / (min* 15 * 75)) * ostvareno < 80 ? { 'width': `${(100 / (min* 15 * 75)) * ostvareno}%`, 'backgroundColor': "orange" } : { 'width': `${(100 / (min* 15 * 75)) * ostvareno}%`, 'backgroundColor': "green" }}
                    className="components__minimum__progres__bar">
                    <p style={(100 / (min* 15 * 75)) * ostvareno < 50 ? { 'color': "red" } : (100 / (min* 15 * 75)) * ostvareno < 80 ? {  'color': "orange" } : { 'color': "green" }}>
                        {min>0?((100 / (min* 15 * 75)) * ostvareno).toFixed(2):0}%</p>
                </div>
            </div>
        </div>
        <div  className='components__minimum components__minimumGreen'>
        <h3>Danas 2. smjena:</h3>
            <h5>Predviđeno:  <strong>{min2* 15 * 75}€</strong>

            </h5>
            <h5>Ostvareno: <strong>{ostvareno2?.toFixed(2)}€</strong></h5>
            <h5>Aktivno radnika: <strong>{min2}</strong></h5>
            <div className="components__minimum__progres">
                <div style={(100 / (min2* 15 * 75)) * ostvareno2 < 50 ? { 'width': `${(100 / (min2* 15 * 75)) * ostvareno2}%`, 'backgroundColor': "red" } : (100 / (min2* 15 * 75)) * ostvareno2 < 80 ? { 'width': `${(100 / (min2* 15 * 75)) * ostvareno2}%`, 'backgroundColor': "orange" } : { 'width': `${(100 / (min2* 15 * 75)) * ostvareno2}%`, 'backgroundColor': "green" }}
                    className="components__minimum__progres__bar">
                    <p style={(100 / (min2* 15 * 75)) * ostvareno2 < 50 ? { 'color': "red" } : (100 / (min2* 15 * 75)) * ostvareno2 < 80 ? {  'color': "orange" } : { 'color': "green" }}>
                        {min2>0?((100 / (min2* 15 * 75)) * ostvareno2).toFixed(2):0}%</p>
                </div>
            </div>

        </div>
        <div className='components__minimum components__minimumOrange'>
            <h3>Jučer 1. smjena:</h3>
            <h5>Predviđeno:  <strong>{minj* 15 * 75}€</strong>

            </h5>
            <h5>Ostvareno: <strong>{ostvarenoj?.toFixed(2)}€</strong></h5>
            <h5>Aktivno radnika: <strong>{minj}</strong></h5>
            <div className="components__minimum__progres">
                <div style={(100 / (minj* 15 * 75)) * ostvarenoj < 50 ? { 'width': `${(100 / (minj* 15 * 75)) * ostvarenoj}%`, 'backgroundColor': "red" } : (100 / (minj* 15 * 75)) * ostvarenoj < 80 ? { 'width': `${(100 / (minj* 15 * 75)) * ostvarenoj}%`, 'backgroundColor': "orange" } : { 'width': `${(100 / (minj* 15 * 75)) * ostvarenoj}%`, 'backgroundColor': "green" }}
                    className="components__minimum__progres__bar">
                    <p style={(100 / (minj* 15 * 75)) * ostvarenoj < 50 ? { 'color': "red" } : (100 / (minj* 15 * 75)) * ostvarenoj < 80 ? {  'color': "orange" } : { 'color': "green" }}>
                        {minj>0 ?((100 / (minj* 15 * 75)) * ostvarenoj).toFixed(2):0}%</p>
                </div>
            </div>
        </div>
        <div  className='components__minimum components__minimumOrange'>
        <h3>Jučer 2. smjena:</h3>
            <h5>Predviđeno:  <strong>{minj2* 15 * 75}€</strong>

            </h5>
            <h5>Ostvareno: <strong>{ostvarenoj2?.toFixed(2)}€</strong></h5>
            <h5>Aktivno radnika: <strong>{minj2}</strong></h5>
            <div className="components__minimum__progres">
                <div style={(100 / (minj2* 15 * 75)) * ostvarenoj2 < 50 ? { 'width': `${(100 / (minj2* 15 * 75)) * ostvarenoj2}%`, 'backgroundColor': "red" } : (100 / (minj2* 15 * 75)) * ostvarenoj2 < 80 ? { 'width': `${(100 / (minj2* 15 * 75)) * ostvarenoj2}%`, 'backgroundColor': "orange" } : { 'width': `${(100 / (minj2* 15 * 75)) * ostvarenoj2}%`, 'backgroundColor': "green" }}
                    className="components__minimum__progres__bar">
                    
                </div>
                <p style={(100 / (minj2* 15 * 75)) * ostvarenoj2 < 50 ? { 'color': "red" } : (100 / (minj2* 15 * 75)) * ostvarenoj2 < 80 ? {  'color': "orange" } : { 'color': "green" }}>
                    {minj2>0  ?((100 / (minj2* 15 * 75)) * ostvarenoj2).toFixed(2): 0}%:
                    </p>
            </div>

        </div>
    </>
}
export default Minimum