import React from "react";
import './index.scss'
import moment from "moment";

const RadnikBonus = (props) => {
    const [promet, setPromet] = React.useState(0)
    const [prometBonus, setPrometBonus] = React.useState(0)
    const [prosjek, setProsjek] = React.useState(0)
    const [prosjekBonus, setProsjekBonus] = React.useState(0)
    const [procenat, setProcenat] = React.useState(0)
    const [procenatBonus, setProcenatBonus] = React.useState(0)
    const [dealsMain, setDeals] = React.useState()
    const [leadsMain, setLeads] = React.useState()
    const [spinner, setSpinner] = React.useState(true)


    const filterData = () => {
        let leads = []
        let deals = []
        leads.push(props.leads[0])
        deals.push(props.deals[0])
        for (let i = 1; i < props.deals.length; i++) {
            if (parseInt(moment(props.deals[i].kreirano).format('MM')) === props.month && parseInt(moment(props.deals[i].kreirano).format('YYYY')) === props.year) {
                deals.push(props.deals[i])
            }
        }
        for (let i = 1; i < props.leads.length; i++) {
            if (parseInt(moment(props.leads[i].kreirano).format('MM')) === props.month && parseInt(moment(props.leads[i].kreirano).format('YYYY')) === props.year) {
                leads.push(props.leads[i])
            }
        }

        setTimeout(() => {
            calculate(deals, leads)
        }, 10000)

    }

    const calculate = (deals, leads) => {
        setDeals(deals)
        setLeads(leads)
        let tempPromet = 0
        let tempProsjek = 0
        let tempLeadovi = 0
        for (let i = 1; i < deals?.length; i++) {
            tempPromet += deals[i].cijena
        }

        tempProsjek = tempPromet / (deals?.length - 1)

        if (tempProsjek >= 60) {
            if (tempProsjek < 50) {
                setPrometBonus(0)
            } else if (tempPromet > 65000) {
                setPrometBonus(1000)
            } else if (tempPromet > 50000) {
                setPrometBonus(600)
            } else if (tempPromet > 35000) {
                setPrometBonus(400)
            } else if (tempPromet > 30000) {
                setPrometBonus(300)
            } else if (tempPromet > 25000) {
                setPrometBonus(150)
            } else if (tempPromet > 20000) {
                setPrometBonus(75)
            } else if (tempPromet > 15000) {
                setPrometBonus(50)
            }
        }else{
            if (tempProsjek < 50) {
                setPrometBonus(0)
            } else if (tempPromet > 65000) {
                setPrometBonus(500)
            } else if (tempPromet > 50000) {
                setPrometBonus(300)
            } else if (tempPromet > 35000) {
                setPrometBonus(200)
            } else if (tempPromet > 30000) {
                setPrometBonus(150)
            } else if (tempPromet > 25000) {
                setPrometBonus(75)
            } else if (tempPromet > 20000) {
                setPrometBonus(37)
            } else if (tempPromet > 15000) {
                setPrometBonus(25)
            }
        }
        for (let i = 1; i < leads?.length; i++) {
            if (leads[i].status === 'CONVERTED') {
                tempLeadovi++
            }
        }


        if (tempProsjek > 75 && tempProsjek<=80 && tempPromet > 30000) {
            setProsjekBonus(50)
        } else if (tempProsjek > 80 && tempPromet > 35000 && tempPromet<=65000) {
            setProsjekBonus(100)
        } else if (tempProsjek > 80 && tempPromet > 65000 && tempProsjek<=90) {
            setProsjekBonus(200)
        } else if (tempProsjek > 90 && tempPromet > 65000&& tempProsjek<=100) {
            setProsjekBonus(300)
        } else if (tempProsjek > 100 && tempPromet > 65000) {
            setProsjekBonus(500)
        }
        if (isNaN(tempProsjek) === false) {
            setProsjek(tempProsjek.toFixed(2))
        } else {
            setProsjek(0)
        }
        if (tempPromet > 35000 && (tempLeadovi / leads.length * 100) > 80) {
            setProcenatBonus(250)
        }


        setPromet(tempPromet.toFixed(2))
        setProcenat(tempLeadovi / leads.length * 100)
        setTimeout(() => {
            setSpinner(false)
        }, 1000)
    }
    React.useEffect(() => {
        filterData()
    }, [props.deals])

    return <div className='component__radnikBonus' style={props.color}>
        {spinner && <div className="component__radnikBonus__spinner">
            <img src="/images/spinner.gif" />
        </div>}
        <p>{props?.deals[0]?.ime}</p>
        <p>{dealsMain?.length - 1}</p>
        <p>{prometBonus}€ ({promet})</p>
        <p>{prosjekBonus}€ ({prosjek})</p>
        <p>{procenatBonus}€ ({procenat.toFixed(2)}%)</p>
        <p>{prometBonus + prosjekBonus + procenatBonus}€</p>

    </div>
}
export default RadnikBonus